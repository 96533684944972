import { useEffect } from 'react';

function App() {
    useEffect(() => {
        const protocol = window.location.protocol; // http: or https:
        const host = window.location.host; // localhost:3000 or example.com
        const afterBaseUrl = window.location.pathname + window.location.search; // /path or /path?query=string
        if (!host.startsWith('www')) window.location.replace(`${protocol}//www.${host}${afterBaseUrl}`);
    }, []);

    return null;
}

export default App;
